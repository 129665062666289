import React from "react"
import "../styles/default.css"
import { Legal, Languages } from "@arnaud-cortisse/donotskip-core"
import { usePageContext } from "@3nvi/gatsby-theme-intl"

const TermsAndConditions: React.FC = () => {
  const { lang } = usePageContext()
  const src = Legal.TermsAndConditionsUrls[lang as Languages.Ids]

  return (
    <div style={{ height: "100vh" }}>
      <iframe width="100%" height="100%" src={src} />
    </div>
  )
}

export default TermsAndConditions
